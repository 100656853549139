@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8ceec;
  background-image: linear-gradient(315deg, #f8ceec 0%, #a88beb 74%);
}

.darkContainer {
  background-color: #1c0c5b;
  background-image: linear-gradient(315deg, #52057b 0%, #1c0c5b 74%);
}

.card {
  width: 360px;
  height: 450px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 25px;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 15px;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 8px;
}

.user-online-indicator {
  width: 15px;
  height: 15px;
  background-color: #53f45a;
  position: absolute;
  bottom: 10px;
  right: 8px;
  border-radius: 50%;
  z-index: 4;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-name h1 {
  font-size: 28px;
  color: #52057b;
  font-weight: 500;
}
.profile-role {
  color: #892CDC;
}

.card-footer {
  width: 85%;
  height: 90px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.social-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-buttons a {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  color: #52057b;
  border-radius: 50%;
  font-size: 23px;
  margin: 0 20px;
}

